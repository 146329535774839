<template>
  <div class="apply-benefits-list">
    <Margins>
      <h6 class="text-normal">
        <span class="header-line">{{ $t('FAQ.TITLE') }}</span>
      </h6>

      <template>
        <i18n
          path="FAQ.TEXTS"
          tag="p"
          class="text-small text-grey margins__double"
        >
          <template #faqLink>
            <a href="/faq">
              {{ $t('FAQ.HERE') }}
            </a>
          </template>
          <template #dealersLink>
            <a href="/#map">
              {{ $t('DEALER_LIST_HERE') }}
            </a>
          </template>
        </i18n>
      </template>
    </Margins>
  </div>
</template>

<script>
import { Margins } from '@/components';
import { constants } from '@/mixins';

export default {
  name: 'ApplyBenefitsList',
  mixins: [constants],
  components: {
    Margins,
  },
};
</script>
