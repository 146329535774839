<template>
  <div class="apply-benefits-list">
    <Margins>
      <h6 class="text-normal">
        <span class="header-line">{{ $t('ABOUT_FAIROWN') }}</span>
      </h6>

      <p
        v-for="(text, key) in texts"
        :key="key"
        :class="[
          'text-small text-grey',
          { ['margins__double']: key === 'TEXT_1' },
        ]"
      >
        {{ text }}
      </p>
    </Margins>
  </div>
</template>

<script>
import { Margins } from '@/components';
import { getDynamicTranslation } from '@/utils';

export default {
  name: 'ApplyBenefitsList',
  components: {
    Margins,
  },
  computed: {
    texts() {
      return getDynamicTranslation(this.$i18n, 'ABOUT_FAIROWN_TEXTS');
    },
  },
};
</script>
