<template>
  <div class="apply-header-picture">
    <img :data-src="imageUrl" class="lazyload" alt="STIHL All Inclusive" />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { bundleHeroImageMap } from '@/constants';

export default {
  name: 'ApplyHeaderPicture',

  computed: {
    ...mapState(['selectedBundle']),

    fileName() {
      const model = this.selectedBundle.additionalData.model || 'BASE';

      return bundleHeroImageMap[model];
    },

    imageUrl() {
      return require(`@/assets/images/${this.fileName}`);
    },
  },
};
</script>
