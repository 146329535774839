<template>
  <Margins>
    <h6 class="remove-bundle-card__title text-normal" v-if="assets.length > 1">
      {{ $tc('YOUR_PRODUCTS', assets.length) }}
    </h6>
    <Card
      color="grey"
      padding="small"
      overflow="none"
      :key="`${bundle.id}-primary-description`"
      class="remove-bundle-card remove-bundle-card__card"
    >
      <b-row align-v="center" align-h="center">
        <img
          v-if="getBundleImageByType('MARKETING_PHOTO')"
          :src="`${IMAGE_BASE_URL}/${getBundleImageByType('MARKETING_PHOTO')}`"
          alt="CustomerFavorite"
          class="lazyload remove-bundle-card__splash"
        />
        <b-col cols="7" sm="6" md="3">
          <img
            :src="`${IMAGE_BASE_URL}/${getBundleImageByType('MAIN_PHOTO')}`"
            class="lazyload"
            :alt="bundle.name"
          />
        </b-col>
        <b-col md="9">
          <div class="remove-bundle-card__content">
            <Margins>
              <h6>{{ bundle.name }}</h6>
              <ul class="remove-bundle-card__additional-info">
                <li v-for="row in description" :key="`${row}-additional-info`">
                  {{ row }}
                </li>
              </ul>
            </Margins>
          </div>
        </b-col>
      </b-row>
    </Card>
  </Margins>
</template>

<script>
import { mapState } from 'vuex';
import { constants } from '@/mixins';
import { Card, Margins } from '@/components';
import { getDescriptionWithBullets, getImageId } from '@/utils/BundleUtil';

export default {
  name: 'ApplyAssetCard',
  mixins: [constants],
  components: {
    Card,
    Margins,
  },
  methods: {
    getBundleImageByType(type) {
      return getImageId(this.selectedBundle?.mediaFiles, type);
    },
  },
  computed: {
    ...mapState(['selectedBundle']),
    assets() {
      return this.selectedBundle?.assets;
    },
    bundle() {
      return this.selectedBundle;
    },
    description() {
      return getDescriptionWithBullets(this.selectedBundle?.description);
    },
  },
};
</script>

<style lang="scss">
.remove-bundle-card__card {
  + .remove-bundle-card__card {
    margin-top: 0.5rem;
  }
}

.remove-bundle-card {
  position: relative;
}

.remove-bundle-card__title {
  @include min-width(sm) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.remove-bundle-card__content {
  font-size: $font-size-small;
  padding: 1rem 0;

  @include min-width(md) {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.remove-bundle-card__additional-info {
  font-size: $font-size-extra-small;
}

.remove-bundle-card__price {
  text-align: center;

  @include min-width(md) {
    text-align: right;
  }
}

.remove-bundle-card__splash {
  background-size: cover;
  height: 4.5rem;
  object-fit: cover;
  position: absolute;
  top: -1.5rem;

  @include min-width(xs) {
    left: -1rem;
  }

  @include min-width(md) {
    left: -2rem;
  }

  @include min-width(lg) {
    left: -2rem;
  }

  @include min-width(xl) {
    left: -2rem;
  }
}
</style>
