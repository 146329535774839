<template>
  <div class="your-plan">
    <Margins>
      <YourPlanTable :showPaymentAmount="showPaymentAmount" />
    </Margins>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { Margins } from '@/components';
import YourPlanTable from './YourPlanTable.vue';
import { constants } from '@/mixins';

export default {
  name: 'YourPlan',
  mixins: [constants],
  components: {
    Margins,
    YourPlanTable,
  },
  props: {
    showPaymentAmount: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapState(['selectedBundle']),
  },
};
</script>

<style lang="scss">
.your-plan {
  @include min-width(sm) {
    padding: 1rem;
  }
}
</style>
